import { Grid, Typography } from '@mui/material';
import { PageContainer } from 'common';
import { useTheme } from '@mui/material';
import { FRIENDS_CONTENT, FriendsContent } from './content';

export const FriendsPageMobile = () => {
  return (
    <PageContainer>
      <Grid container direction="column" px={2} spacing={10} pb={5}>
        {FRIENDS_CONTENT.map((friend, idx) => (
          <Grid
            container
            item
            direction="column"
            spacing={2}
            key={`friend-grid${idx}`}
          >
            <FriendImage img={friend.img} key={`friend-img-${idx}`} />
            <FriendContent friend={friend} key={`friend-content${idx}`} />
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
};

const FriendImage = ({ img }: { img: string }) => {
  const theme = useTheme();

  return (
    <Grid item container justifyContent="center" alignContent="center">
      <img
        style={{
          borderRadius: '50%',
          maxWidth: '50vw',
          maxHeight: '50vw',
          border: `5px solid ${theme.palette.primary.dark}`,
        }}
        src={img}
        alt={`friend-${img}`}
      />
    </Grid>
  );
};

const FriendContent = ({ friend }: { friend: FriendsContent }) => {
  return (
    <Grid container item direction="column" spacing={1} textAlign="center">
      <Grid item>
        <Typography variant="h4" color="secondary" fontWeight="bold">
          {friend.name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="secondary">
          {friend.title}
        </Typography>
      </Grid>
      <Grid item textAlign="left">
        <Typography sx={{ whiteSpace: 'pre-line' }}>{friend.bio}</Typography>
      </Grid>
    </Grid>
  );
};
