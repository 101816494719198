import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PageContainer } from 'common';
import FeelsGoodMan from 'assets/story/feelsgoodman.png';
import SwcWalks from 'assets/story/swcwalks.jpg';
import Proposal from 'assets/story/proposal.jpg';
import Kitties from 'assets/story/kitties.jpg';

export const StoryPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageContainer>
      <Box display="flex" justifyContent="center" pb={5}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          px={1}
          spacing={1}
          width={isSmallScreen ? '100vw' : '50vw'}
        >
          <Grid item textAlign="center">
            <Typography variant="h6">Our Story 🌹</Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography>We'll make sure to keep it short and sweet.</Typography>
          </Grid>

          <Grid item mt={2}>
            <Typography variant="h6">Part 1: Memes</Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography>
              Savannah and I have been friends since grade 10 (14ish years old).
              We shared a pretty weird and niche sense of Internet humour
              together which accelerated our friendship quite a bit.
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" mt={2}>
            <img
              src={FeelsGoodMan}
              alt="pepe"
              style={{
                width: isSmallScreen ? '50vw' : '15vw',
              }}
            />
          </Grid>
          <Grid item mt={2}>
            <Typography>
              I started tutoring Savannah some math during lunchtime at school
              and we became closer (yay!).
            </Typography>
            <Typography>
              Shortly after, Savannah was supposed to get me to ask one of her
              friends to semi but instead I ended up asking Savannah (good
              going, Savannah). We had a wonderful time at semi - although
              Savannah's eggplant parm did not look so wonderful.
            </Typography>
          </Grid>

          <Grid item mt={2}>
            <Typography variant="h6">Part 2: Flowers</Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography>
              A mutual friend of ours, Aaron, spilled the beans that one of us
              actually
              <i> likes likes</i> the other (ooooh) - I won't say who for
              storytelling's sake.
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography>
              We went on several nervous dates until I asked Savannah to be my
              girlfriend at the beautiful location of Burgoyne Woods (not
              actually beautiful) with a beautiful bouquet of roses (actually
              beautiful) on April 28, 2017.
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" mt={2}>
            <img
              src={SwcWalks}
              alt="Us at High School"
              style={{
                width: isSmallScreen ? '50vw' : '15vw',
              }}
            />
          </Grid>
          <Grid item mt={2}>
            <Typography>
              For the remainder of high school, we kept studying hard and also
              worked part-time jobs together at Tim Horton's! Oh, and we won
              cutest couple at the next semi and at prom 😎.
            </Typography>
          </Grid>

          <Grid item mt={2}>
            <Typography variant="h6">Part 3: Geese</Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography>
              Savannah went to Wilfrid Laurier University and I went to the
              University of Waterloo!
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography>
              We both worked super hard and supported each other through some
              tough years. I continue to be unbelievably proud of Savannah's
              accomplishments and am super grateful for her supporting me
              through 5 tough years of school.
            </Typography>
          </Grid>

          <Grid item mt={2}>
            <Typography variant="h6">Part 4: Rings and Cats</Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography>
              Savannah moved to Toronto in 2022 for grad school while I stayed
              in Waterloo to finish up my last year of school. I eventually
              moved to Toronto in 2023 after graduating for work.
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography>
              On a trip to Quebec City, I proposed to Savannah on October 22,
              2023. I was extremely nervous the whole trip.
            </Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography>She said yes (I have photo proof)!</Typography>
          </Grid>
          <Grid item container justifyContent="center" mt={2}>
            <img
              src={Proposal}
              alt="Proposal"
              style={{
                width: isSmallScreen ? '50vw' : '15vw',
              }}
            />
          </Grid>

          <Grid item mt={2}>
            <Typography>
              Not too long after, we got two cats! We saved Remy from the
              shelter, and Toast from a thug.
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" mt={2}>
            <img
              src={Kitties}
              alt="Our kitties"
              style={{
                width: isSmallScreen ? '70vw' : '30vw',
              }}
            />
          </Grid>
          <Grid item mt={2}>
            <Typography variant="h6">Part 5: Onwards...</Typography>
          </Grid>
          <Grid item mt={2}>
            <Typography>
              If you've made it this far - thanks for taking the time to read
              our story!
            </Typography>
            <Typography>
              We're both very excited to celebrate our big day together with
              you. (We're also excited to celebrate the day with those that
              didn't read this far, too.)
            </Typography>
          </Grid>
          <Grid item mt={2} textAlign="right">
            <Typography>Matthew & Savannah</Typography>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};
