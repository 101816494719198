import { Box, Typography } from '@mui/material';
import { PageContainer } from 'common/PageContainer';

export const Error = () => {
  return (
    <PageContainer>
      <Box display="flex" flexDirection="column" textAlign="center">
        <Typography variant="h4">404 Not Found 😡</Typography>
        <Typography mt={4}>Dan? Saad? Jasper?</Typography>
        <Typography>Of course there's a 404 page.</Typography>
        <Typography>Get out of here.</Typography>
      </Box>
    </PageContainer>
  );
};
