import { MoreHoriz } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { getMenuButtonStyle } from './style';

export const NavigationMenuMobile = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetScroll = () => window.scrollTo(0, 0);

  return (
    <Grid
      item
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Link to={`/`} style={{ textDecoration: 'none' }}>
          <Button
            {...getMenuButtonStyle(location.pathname === '/')}
            onClick={resetScroll}
          >
            <Typography>Home</Typography>
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Link to={`/info`} style={{ textDecoration: 'none' }}>
          <Button
            {...getMenuButtonStyle(location.pathname === '/info')}
            onClick={resetScroll}
          >
            <Typography>Info</Typography>
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Button
          {...getMenuButtonStyle(false)}
          onClick={() =>
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLScKfTAti88Ey5RNHKGl7wAw9ykh8WUqhwiviS4PjFua1b9uhA/viewform?pli=1',
              '_blank',
            )
          }
        >
          <Typography>RSVP</Typography>
        </Button>
      </Grid>

      <Grid item>
        <IconButton size="large" onClick={handleMenu}>
          <MoreHoriz
            sx={{
              color: Boolean(anchorEl)
                ? (theme) => theme.palette.secondary.main
                : 'black',
            }}
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: -40,
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link to={`/story`} style={{ textDecoration: 'none' }}>
              <Button
                {...getMenuButtonStyle(location.pathname === '/story')}
                onClick={resetScroll}
              >
                <Typography>Our Story</Typography>
              </Button>
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to={`/travel`} style={{ textDecoration: 'none' }}>
              <Button
                {...getMenuButtonStyle(location.pathname === '/travel')}
                onClick={resetScroll}
              >
                <Typography>Travel</Typography>
              </Button>
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to={`/friends`} style={{ textDecoration: 'none' }}>
              <Button
                {...getMenuButtonStyle(location.pathname === '/friends')}
                onClick={resetScroll}
              >
                <Typography>The Party</Typography>
              </Button>
            </Link>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};
