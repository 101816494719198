import { Button } from '@mui/material';
import { ComponentProps } from 'react';

export const getMenuButtonStyle: (
  active?: boolean,
) => ComponentProps<typeof Button> = (active) => ({
  variant: 'text',
  disableTouchRipple: true,
  sx: {
    color: 'black',
    textTransform: 'none',
    '&:hover': {
      color: (theme) => theme.palette.secondary.main,
      textDecoration: 'underline',
      textUnderlineOffset: '6px',
    },
    ...(active && {
      color: (theme) => theme.palette.secondary.main,
      textDecoration: 'underline',
      textUnderlineOffset: '6px',
    }),
  },
});
