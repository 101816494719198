import { useMediaQuery, useTheme } from '@mui/material';
import { FriendsPageDesktop } from './FriendsPageDesktop';
import { FriendsPageMobile } from './FriendsPageMobile';

export const FriendsPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return <>{isSmallScreen ? <FriendsPageMobile /> : <FriendsPageDesktop />}</>;
};
