import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const PageContainer = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box px={isSmallScreen ? 1 : 10} pt={3}>
      {children}
    </Box>
  );
};
