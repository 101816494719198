import { Home, Restaurant, WineBar } from '@mui/icons-material';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PageContainer } from 'common';
import { RestaurantTable } from './RestaurantsTable';
import { WineryTable } from './WineTable';

export const TravelPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageContainer>
      <Box display="flex" justifyContent="center" pb={5}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          px={1}
          spacing={3}
          width={isSmallScreen ? '100vw' : '50vw'}
        >
          <Grid item container spacing={2}>
            <Grid item container direction="row" spacing={1}>
              <Grid item>
                <Home color="secondary" />
              </Grid>
              <Grid item>
                <Typography fontWeight="bold">
                  Accommodation Information
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>More information to follow shortly.</Typography>
            </Grid>
          </Grid>

          <Grid item container spacing={2} mt={2}>
            <Grid item container direction="row" spacing={1}>
              <Grid item>
                <Restaurant color="secondary" />
              </Grid>
              <Grid item>
                <Typography fontWeight="bold">Good Food</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>
                Some of our favourite foods in the region (not in any particular
                order).
              </Typography>
            </Grid>
            <Grid item>
              <RestaurantTable />
            </Grid>
          </Grid>

          <Grid item container spacing={2} mt={2}>
            <Grid item container direction="row" spacing={1}>
              <Grid item>
                <WineBar color="secondary" />
              </Grid>
              <Grid item>
                <Typography fontWeight="bold">Wine</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>
                Our favourite wineries (not in any particular order).
              </Typography>
            </Grid>
            <Grid item>
              <WineryTable />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};
