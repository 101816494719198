import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import LaunchImage from 'assets/launch/launch.png';
import { PageContainer } from 'common';

export const LaunchPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <figure>
          <Box justifyContent="center" display="flex">
            <img
              src={LaunchImage}
              style={{ maxWidth: `${isSmallScreen ? '90vw' : '40vw'}` }}
              alt="Launch"
            />
          </Box>

          <figcaption style={{ textAlign: 'center', fontStyle: 'italic' }}>
            <Typography sx={{ mt: isSmallScreen ? 1 : 2 }}>
              @ Chateau Frontenac - moments before popping the question...
            </Typography>
          </figcaption>
        </figure>
      </Box>
    </PageContainer>
  );
};
