import { Grid, Typography } from '@mui/material';
import { PageContainer } from 'common';
import { useTheme } from '@mui/material';
import { FRIENDS_CONTENT, FriendsContent } from './content';

export const FriendsPageDesktop = () => {
  return (
    <PageContainer>
      <Grid container direction="column" spacing={5} pb={5}>
        {FRIENDS_CONTENT.map((friend, idx) => {
          return idx % 2 === 0 ? (
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              spacing={10}
              key={`friend-grid${idx}`}
            >
              <FriendImage
                img={friend.img}
                flexStart={false}
                key={`friend-img-${idx}`}
              />
              <FriendContent friend={friend} key={`friend-content${idx}`} />
            </Grid>
          ) : (
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-end"
              textAlign="right"
              spacing={10}
              key={`friend-grid-${idx}`}
            >
              <FriendContent friend={friend} key={`friend-content-${idx}`} />
              <FriendImage
                img={friend.img}
                flexStart={true}
                key={`friend-img-${idx}`}
              />
            </Grid>
          );
        })}
      </Grid>
    </PageContainer>
  );
};

const FriendImage = ({
  img,
  flexStart,
}: {
  img: string;
  flexStart?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Grid
      item
      container
      xs={3}
      justifyContent={flexStart ? 'flex-start' : 'flex-end'}
      alignContent="center"
    >
      <img
        style={{
          borderRadius: '50%',
          maxWidth: '15vw',
          maxHeight: '15vw',
          border: `5px solid ${theme.palette.primary.dark}`,
        }}
        src={img}
        alt={`friend-${img}`}
      />
    </Grid>
  );
};

const FriendContent = ({ friend }: { friend: FriendsContent }) => {
  return (
    <Grid container item direction="column" xs={6} spacing={2}>
      <Grid item>
        <Typography variant="h4" color="secondary" fontWeight="bold">
          {friend.name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="secondary">
          {friend.title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ whiteSpace: 'pre-line' }}>{friend.bio}</Typography>
      </Grid>
    </Grid>
  );
};
