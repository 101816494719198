import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const rows = [
  {
    name: 'Beechwood Doughnuts',
    type: 'Dessert',
    notes:
      'Must try - best doughnuts ever. \n Go early before they run out of the popular flavours. \n Fun fact: Savannah was there when Trudeau stopped by for some doughnuts here!',
    mapsLink: 'https://maps.app.goo.gl/FUR2hXqfrh21oQoR9',
  },
  {
    name: 'Pharmacii',
    type: 'Korean Fusion',
    notes:
      'Really cool restaurant with a super cool vibe and amazing drinks. Bit on the pricier side.',
    mapsLink: 'https://maps.app.goo.gl/tSgDk1xurZgAN2mU9',
  },
  {
    name: 'Rise Above',
    type: 'Vegan',
    notes: `The food is delicious here - don't let the "vegan" label fool you. \n Also many of our first dates were here!`,
    mapsLink: 'https://maps.app.goo.gl/ro45i5r3nA8BRDPB8',
  },
  {
    name: 'St. Joseph Bakery',
    type: 'Subs and Dessert (trust us)',
    notes: `Polish bakery and deli - they have amazing egg tarts and we dream about their subs every night. \n We wouldn't be being true to ourselves if we didn't add St. Jo's to this list.`,
    mapsLink: 'https://maps.app.goo.gl/gXj5PjYCtcRGMdaH6',
  },
  {
    name: `Bugsy's`,
    type: 'Pub',
    notes:
      'Solid pub with a great atmosphere and great food. \n Fun fact: Matt had his first shot of tequila here - thanks Jasper!',
    mapsLink: 'https://maps.app.goo.gl/R13noXTcX4hG8NmS8',
  },

  {
    name: 'Avondale Dairy Bar',
    type: 'Dessert',
    notes: `Iconic Niagara region ice cream bar. Really unique location completely out in the countryside.`,
    mapsLink: 'https://maps.app.goo.gl/ZDLReipTaHBUXvyE7',
  },
];

export const RestaurantTable = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Restaurant</TableCell>
            {!isSmallScreen && <TableCell align="left">Type</TableCell>}
            <TableCell align="left">Notes</TableCell>
            <TableCell align="right">Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              {!isSmallScreen && <TableCell align="left">{row.type}</TableCell>}
              <TableCell align="left" style={{ whiteSpace: 'pre-line' }}>
                {row.notes}
              </TableCell>
              <TableCell align="right">
                <Link
                  href={row.mapsLink}
                  target="_blank"
                  rel="noopener"
                  color="secondary"
                >
                  link
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
