import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: `"Playfair Display", serif`,
  },
  palette: {
    primary: {
      main: '#D9E2D2',
    },
    secondary: {
      main: '#C79191',
    },
  },
});
