import { NavigationBar, Error } from 'common';
import { ThemeProvider } from '@mui/material';
import { theme } from 'theme';
import {
  FriendsPage,
  InfoPage,
  LaunchPage,
  StoryPage,
  TravelPage,
} from 'pages';
import { Route, Routes } from 'react-router-dom';
import './App.css';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<LaunchPage />} />
        <Route path="/story" element={<StoryPage />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="/travel" element={<TravelPage />} />
        <Route path="/friends" element={<FriendsPage />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
