import {
  AppBar,
  Grid,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { NavigationMenu } from './NavigationMenu';
import { NavigationMenuMobile } from './NavigationMenuMobile';

const calculateDaysRemaining = () => {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date();
  const secondDate = new Date(2025, 6, 19);

  return Math.round(
    Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay),
  );
};

export const NavigationBar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar position="sticky" sx={{ p: 0 }}>
      <Toolbar>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          pt={4}
          pb={2}
          sx={{
            width: `100vw`,
            maxWidth: '100%',
            textAlign: 'center',
          }}
        >
          <Grid item>
            <Typography variant="h4">
              {isSmallScreen ? 'MATT & SAV' : 'MATTHEW & SAVANNAH'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              July 19, 2025 @{' '}
              <Link
                sx={{ color: 'black' }}
                href="https://theharewineco.com/"
                target="_blank"
                rel="noopener"
              >
                The Hare
              </Link>
              , Niagara-on-the-Lake
            </Typography>
          </Grid>
          {!isSmallScreen && (
            <Grid item>
              <Typography variant="subtitle1">
                {`${calculateDaysRemaining()} days to go!`}
              </Typography>
            </Grid>
          )}
          <Grid item mt={2}>
            {!isSmallScreen ? <NavigationMenu /> : <NavigationMenuMobile />}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
