import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getMenuButtonStyle } from './style';
import { Link, useLocation } from 'react-router-dom';

export const NavigationMenu = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const resetScroll = () => window.scrollTo(0, 0);

  return (
    <Grid
      item
      container
      spacing={isSmallScreen ? 1 : 6}
      justifyContent="center"
    >
      <Grid item>
        <Link to={`/`} style={{ textDecoration: 'none' }}>
          <Button
            {...getMenuButtonStyle(location.pathname === '/')}
            onClick={resetScroll}
          >
            <Typography>Home</Typography>
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Link to={`/story`} style={{ textDecoration: 'none' }}>
          <Button
            {...getMenuButtonStyle(location.pathname === '/story')}
            onClick={resetScroll}
          >
            <Typography>Our Story</Typography>
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Link to={`/info`} style={{ textDecoration: 'none' }}>
          <Button
            {...getMenuButtonStyle(location.pathname === '/info')}
            onClick={resetScroll}
          >
            <Typography>Information</Typography>
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Link to={`/travel`} style={{ textDecoration: 'none' }}>
          <Button
            {...getMenuButtonStyle(location.pathname === '/travel')}
            onClick={resetScroll}
          >
            <Typography>Travel</Typography>
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Link to={`/friends`} style={{ textDecoration: 'none' }}>
          <Button
            {...getMenuButtonStyle(location.pathname === '/friends')}
            onClick={resetScroll}
          >
            <Typography>Wedding Party</Typography>
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Button
          {...getMenuButtonStyle(false)}
          onClick={() =>
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLScKfTAti88Ey5RNHKGl7wAw9ykh8WUqhwiviS4PjFua1b9uhA/viewform?pli=1',
              '_blank',
            )
          }
        >
          <Typography>RSVP</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
