import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from '@mui/material';

const rows = [
  {
    name: 'The Hare Wine Co.',
    notes: `A winery so good I bet we'll see everyone there at some point!`,
    mapsLink: 'https://maps.app.goo.gl/YHCRvnbKgbu8JpVd6',
  },
  {
    name: 'Wayne Gretzy Estates',
    notes: `Honestly taste is mid BUT 100% best value (the whiskey samples are definitely more than samples 👀)`,
    mapsLink: 'https://maps.app.goo.gl/Dpzmt2MjhAMBATfPA',
  },
  {
    name: 'Inniskillin',
    notes:
      'You can buy a bottle and enjoy it on their patio there! (at least last time we went)',
    mapsLink: 'https://maps.app.goo.gl/tFbDcnzqb2XxcpZQ6',
  },
  {
    name: 'Megalomaniac',
    notes:
      'Gotta drive up a gravel road to get here but the views are worth it!',
    mapsLink: 'https://maps.app.goo.gl/Cxm8S9xNU4Dm93LA6',
  },
];

export const WineryTable = () => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Winery</TableCell>
            <TableCell align="left">Notes</TableCell>
            <TableCell align="right">Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left" style={{ whiteSpace: 'pre-line' }}>
                {row.notes}
              </TableCell>
              <TableCell align="right">
                <Link
                  href={row.mapsLink}
                  target="_blank"
                  rel="noopener"
                  color="secondary"
                >
                  link
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
