import { CalendarMonth, Checkroom, LocationOn } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PageContainer } from 'common';
import Cats from 'assets/info/cats.jpg';

export const InfoPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageContainer>
      <Box display="flex" justifyContent="center" pb={5}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          px={1}
          spacing={3}
          width={isSmallScreen ? '100vw' : '50vw'}
        >
          <Grid item>
            <Typography fontWeight="bold">General Information</Typography>
          </Grid>

          <Grid item container>
            <Grid item container direction="row" spacing={1}>
              <Grid item>
                <LocationOn color="secondary" />
              </Grid>
              <Grid item>
                <Typography color="secondary" fontWeight="bold">
                  Location
                </Typography>
              </Grid>
            </Grid>
            <Grid item ml={4}>
              <Typography>
                769 Niagara Stone Rd, Niagara-on-the-Lake, ON L0S 1J0
              </Typography>
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item container direction="row" spacing={1}>
              <Grid item>
                <CalendarMonth color="secondary" />
              </Grid>
              <Grid item>
                <Typography color="secondary" fontWeight="bold">
                  Date
                </Typography>
              </Grid>
            </Grid>
            <Grid item ml={4}>
              <Typography>
                July 19, 2025 — Please arrive by 4:30PM (EDT).
              </Typography>
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item container direction="row" spacing={1}>
              <Grid item>
                <Checkroom color="secondary" />
              </Grid>
              <Grid item>
                <Typography color="secondary" fontWeight="bold">
                  Attire
                </Typography>
              </Grid>
            </Grid>
            <Grid item ml={4}>
              <Typography>The dress code is formal.</Typography>
              <Typography>
                We kindly ask you to wear either a suit, an evening gown, or a
                midi-length dress.
              </Typography>
              <Typography>
                Additionally, we kindly ask you to refrain from wearing white or
                cream-coloured attire.
              </Typography>
            </Grid>
          </Grid>

          <Grid item mt={2}>
            <Typography fontWeight="bold">
              Frequently Asked Questions
            </Typography>
          </Grid>

          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography color="secondary" fontWeight="bold">
                When is the RSVP deadline?
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Please submit your RSVP by <b>June 14, 2025</b>. You can RSVP by
                clicking here:{' '}
                <Button
                  variant="contained"
                  size="small"
                  sx={{ color: 'black' }}
                  onClick={() =>
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLScKfTAti88Ey5RNHKGl7wAw9ykh8WUqhwiviS4PjFua1b9uhA/viewform?pli=1',
                      '_blank',
                    )
                  }
                >
                  <Typography>RSVP</Typography>
                </Button>
              </Typography>
            </Grid>
          </Grid>

          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography color="secondary" fontWeight="bold">
                Can I bring a plus-one?
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Unfortunately, we are unable to accommodate anyone who does not
                have an invitation. We appreciate your understanding!
              </Typography>
            </Grid>
          </Grid>

          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography color="secondary" fontWeight="bold">
                Do you have a registry?
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                We would be grateful for any contributions to our future
                together. Instead of a traditional gift registry, a monetary
                gift would be incredibly appreciated and will go towards many
                treats for our cats Toast & Remy.
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <figure>
                <img
                  src={Cats}
                  alt="cats!"
                  style={{ width: isSmallScreen ? '50vw' : '15vw' }}
                />
                <figcaption
                  style={{ textAlign: 'center', fontStyle: 'italic' }}
                >
                  <Typography sx={{ mt: 1 }}>Obligatory cat tax</Typography>
                </figcaption>
              </figure>
            </Grid>
          </Grid>

          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography color="secondary" fontWeight="bold">
                Who made this awesome website?
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Not sure - but they must be really talented and have a great eye
                for design!
              </Typography>
            </Grid>
          </Grid>

          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography color="secondary" fontWeight="bold">
                {`My question isn't listed here :(`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Sorry! Please do not hesitate to reach out to us at:
                hyeuntremblay[at]gmail[dot]com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};
